<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Zuletzt verkauft</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            Zuletzt verkauft
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card @click="showSearch">
        <ion-card-header>
          <ion-card-subtitle v-if="showResultCount">{{ totalInserats }} Ergebnisse gefunden</ion-card-subtitle>
          <small v-if="showResultCount">Klicken zum suchen</small>
          <form v-on:submit.prevent="search" v-if="!showResultCount">
            <ion-searchbar show-cancel-button="always" enterkeyhint="search" :onIonCancel="hideSearch" v-model="searchInput" animated></ion-searchbar>
            <ion-card-subtitle v-if="!isLoading && totalInserats > 0 && submittedSearch" style="text-align: center;">{{ totalInserats }} Ergebnisse gefunden</ion-card-subtitle>
          </form>
        </ion-card-header>
      </ion-card>

      <div v-if="!isLoading">
        <inseratCard v-for="(inserat, key) in inserats" :key="key" :id="inserat.uuid" :clickable="false"></inseratCard>
        <ion-infinite-scroll
            @ionInfinite="loadMoreInserats($event)"
            threshold="100px"
            id="infinite-scroll"
        >
          <ion-infinite-scroll-content
              loading-spinner="dots"
              loading-text="Weitere Inserate werden geladen">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>

      <div class="container" v-if="isLoading">
        <ion-spinner name="dots"></ion-spinner>
      </div>

      <div class="container" v-if="!isLoading && inserats.length === 0">
        <strong>Keine Ergebnisse</strong>
        <p>Versuch es mit einem anderen Begriff.</p>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonSearchbar,
  IonSpinner,
  toastController,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import inseratCard from '../../components/inseratCard.vue';

export default defineComponent({
  name: 'ShowAllLastBuyed',
  data () {
    return {
      inserats: [],
      totalInserats: 0,

      showResultCount: true,
      searchInput: "",
      isLoading: false,
      submittedSearch: false,
      page: 1,
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonSearchbar,
    IonSpinner,
    inseratCard,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  created() {



    this.isLoading = true;
    axios.get(process.env.VUE_APP_API_URL + '/inserats?isSold=true&order[soldAt]=desc')
        .then(response => {
          this.inserats = response.data["hydra:member"];
          this.totalInserats = response.data["hydra:totalItems"];
        }).finally(() => {
          this.isLoading = false;
        });
  },
  methods: {
    loadMoreInserats (ev) {
      setTimeout(() => {

        // check if there are more than 30 items
        if (this.totalInserats > 30){
          // load inserats from next page
          this.page += 1;

          let apiUrl = process.env.VUE_APP_API_URL + '/inserats?page='+this.page+'&isSold=true&order[soldAt]=desc';

          // check if search filter is applied
          if (!this.showResultCount && this.removeWhitespaces(this.searchInput) !== ""){
            // add search filter
            apiUrl += '&title=' + this.removeWhitespaces(this.searchInput);
          }

          axios.get(apiUrl)
              .then(response => {

                response.data["hydra:member"].forEach(element => {
                  this.inserats.push(element);
                });

              }).finally(() => {
            ev.target.complete();
            ev.target.disabled = true;
          });

        } else {
          this.openToast('Keine weiteren Inserate gefunden.', 2000);
          ev.target.complete();
          ev.target.disabled = true;
        }

      }, 500);
    },
    search() {

      if (this.removeWhitespaces(this.searchInput) == ""){
          this.openToast('Eingabe darf nicht leer sein.', 2000);
      } else {
        this.isLoading = true;
        this.submittedSearch = true;

        axios.get(process.env.VUE_APP_API_URL + '/inserats?isSold=true&order[soldAt]=desc&title=' + this.removeWhitespaces(this.searchInput))
            .then(response => {
              this.inserats = response.data["hydra:member"];
              this.totalInserats = response.data["hydra:totalItems"];
            })
            .finally(() => {

              if (this.totalInserats == 0){
                this.isLoading = true;
                axios.get(process.env.VUE_APP_API_URL + '/inserats?isSold=true&order[soldAt]=desc&createdBy.username=' + this.removeWhitespaces(this.searchInput))
                    .then(response => {
                      this.inserats = response.data["hydra:member"];
                      this.totalInserats = response.data["hydra:totalItems"];
                    })
                    .finally(() => {
                      this.isLoading = false;
                    })
              } else {
                this.isLoading = false;
              }
            })
      }

    },
    removeWhitespaces(str){
      return str.trim();
    },
    showSearch() {
      // hide results count
      this.showResultCount = false;
    },
    hideSearch(){
      this.searchInput = "";
      this.showResultCount = true;
      this.submittedSearch = false;

      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/inserats?isSold=true&order[soldAt]=desc')
          .then(response => {
            this.inserats = response.data["hydra:member"];
            this.totalInserats = response.data["hydra:totalItems"];
          }).finally(() => {
            this.isLoading = false;
          });

    },
    async openToast(message: string, duration: number, color: string) {

      if (!color){
        color = "primary";
      }

      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
  }
});

</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

</style>
